<template>
  <card-item :loading="dashboard.loading" legend="Financeiro" ch-legend="RESUMO DE CONTAS A PAGAR, RECEBER E EM ATRASO" icon="user-friends" icon-type="fa">
    <template v-slot:card-icon>
      <svg style="width: 24px; height: 24px" fill="currentColor" fill-rule="evenodd" height="100%" viewBox="0 0 24 24"
           width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M3 7h9v2H3z" opacity=".4"></path>
        <path d="M12 7h9v2h-9z" opacity=".3"></path>
        <path d="M5 11h14v2H5z" opacity=".6"></path>
        <path d="M4 5a1 1 0 0 0-1 1v1h9V5H4z"></path>
        <path d="M20 5h-8v2h9V6a1 1 0 0 0-1-1z" opacity=".9"></path>
        <path d="M12 11V9H3v9a1 1 0 0 0 1 1l8-.004V13H5v-2h7zm-4 4v2H5v-2h3z"></path>
        <path d="M5 15h3v2H5z" opacity=".4"></path>
        <path d="M12 9v2h7v2h-7v5.996l8-.004a1 1 0 0 0 1-1V9h-9zm3 7h-1v-1h1v1zm3 0h-1v-1h1v1z" opacity=".9"></path>
        <path d="M14 15h1v1h-1zm3 0h1v1h-1z" opacity=".6"></path>
      </svg>
    </template>
    <!--    <div class="card-link-column">-->
    <card-item-link @click.native="$router.push({name: 'financeiro.contas', hash: '#receber'})" icon="file-invoice-dollar" legend="Contas a receber"
                    :subtitle="'Hoje: ' + stats.contasReceber.hoje.total + ' - Amanhã: ' + stats.contasReceber.amanha.total"
                    icon-type="fa"/>
    <card-item-link @click.native="$router.push({name: 'financeiro.contas', hash: '#pagar'})" icon="receipt" legend="Contas a pagar"
                    :subtitle="'Hoje: ' + stats.contasPagar.hoje.total + ' - Amanhã: ' + stats.contasPagar.amanha.total"
                    icon-type="fa"/>
    <!--    </div>-->
    <!--    <div class="diviser"></div>-->
    <card-item-link @click.native="$router.push({name: 'financeiro.contas', query: {filtro: 'atraso'}, hash: '#receber'})" icon="calendar-times" legend="Vendas em atraso" :subtitle="stats.vendasEmAtraso.total"
                    :right="'R$ ' + $options.filters.moeda(stats.vendasEmAtraso.valor)" icon-type="fa"/>
    <card-item-link @click.native="$router.push({name: 'financeiro.contas', query: {filtro: 'atraso'}, hash: '#pagar'})" icon="exclamation-triangle" legend="Compromissos em atraso" :subtitle="stats.contasPagarEmAtraso.total"
                    :right="'R$ ' + $options.filters.moeda(stats.contasPagarEmAtraso.valor)" icon-type="fa"/>
<!--    <div class="diviser"></div>
    <a @click="$router.push({name: 'financeiro.contas'})" class="card-btn">
      <u-icon name="arrow_forward"/>
      Ir para o financeiro
    </a>-->
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import CardItemLink from '@/reuse/card/CardItemLink'
import Mixin from './mixin'

let mock = {
  'contasReceber': {
    'hoje': {
      total: 0,
      valor: 0
    },
    'amanha':  {
      total: 0,
      valor: 0
    }
  },
  'contasPagar': {
    'hoje':  {
      total: 0,
      valor: 0
    },
    'amanha':  {
      total: 0,
      valor: 0
    }
  },
  'vendasEmAtraso': {
    'total': 0,
    'valor': 0.00
  },
  'contasPagarEmAtraso': {
    'total': 0,
    'valor': 0.00
  }
}

export default {
  name: "FinanceiroStats",
  mixins: [Mixin],
  components: {CardItemLink, CardItem},
  computed: {
    stats() {
      if (!this.basicData || !this.basicData.financeiro) {
        return mock
      }
      return this.basicData.financeiro
    }
  }
}
</script>
