<template>
  <card-item alert-border="alert" legend="Quadro de Avisos" icon="newspaper" icon-type="fa">
    <div class="card-content">
      <div style="text-align: justify; cursor: default">
        <span style="width: 10px; height: 10px; display: inline-block; background-color: #FDCD18; border-radius: 10px; margin-left: -16px; margin-right: 6px"></span><strong>Nenhum aviso importante até o momento</strong>
      </div>
    </div>
<!--    <div class="diviser"></div>
    <a class="card-btn">
      <u-icon name="arrow_forward"/>
      Ir para o sistema de avisos
    </a>-->
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'

export default {
  name: "SoftwareAvisos",
  components: {CardItem}
}
</script>
