<template>
  <card-item :loading="loading" alert-border="alert" :legend="label" icon="book" icon-type="fa" class="card-limit">
    <div class="card-content">
      <div v-if="loading">
        <sl-loading content="" class="size-18"/>
      </div>
      <div v-else-if="!movimentacoes || !movimentacoes.length" style="text-align: justify; cursor: default">
        <span
            style="width: 10px; height: 10px; display: inline-block; background-color: #FDCD18; border-radius: 10px; margin-left: -16px; margin-right: 6px"></span><strong>Nenhuma
        movimentação nova até o momento.</strong>
      </div>
      <div v-else>
        <div @click="abrir(m.movimentacao.id, m.id)" class="dash-push-item" v-for="m in movimentacoes" :key="m.id">
          <u-icon class="alert-icon" name="exclamation-triangle" type="fa" />
          <div class="processo">{{m.movimentacao.processo.numero|formataNumeroProcesso}}</div>
          <div class="texto">{{m.movimentacao.descricao}}</div>
        </div>
      </div>
      <div class="date-filter m-t">
        <erp-s-field view="ll" label="Buscar por data">
          <div style="display: flex; flex-wrap: nowrap">
          <datetime-input @keydown.enter="load" input-class="app-input" date-type="date" date-format="##/##/####"
                          v-model.lazy="filtroData"
                          simpleBorder
          />
            <e-btn @click="load" label="Buscar" />
          </div>
        </erp-s-field>
      </div>
    </div>
    <template v-slot:card-footer>
      <div class="diviser"></div>
      <a @click="$router.push('/andamentos')" class="card-btn">
        <u-icon name="arrow_forward"/>
        Ir para movimentação de processos
      </a>
    </template>
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import {list, viewNotify} from "@/domain/processos/services/notificacaoMovimentacao"
import SlLoading from "@/components/layout/components/Loading"
import windowMovimentacaoProcesso from "@/components/processos/components/processos/window/windowMovimentacaoProcesso";
import DatetimeInput from "@/reuse/input/Datetime"
import {ErpSField} from "uloc-vue-plugin-erp"
import {datePtToEn} from "@/utils/date"

export default {
  name: "SoftwareAvisos",
  components: {
    SlLoading,
    CardItem,
    DatetimeInput,
    ErpSField
  },
  data() {
    return {
      loading: false,
      movimentacoes: null,
      filtroData: null
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    label () {
      if (!this.movimentacoes || !this.movimentacoes.length) {
        return 'Andamento de Processos'
      }
      return `Andamento de Processos (${this.movimentacoes.length})`
    }
  },
  methods: {
    load() {
      this.loading = true
      let extraFilters = []
      this.filtroData && extraFilters.push(`data=${datePtToEn(this.filtroData)}`)
      console.log(this.filtroData)
      !this.filtroData && extraFilters.push(`lido=0`)
      list(100, 1, '&' + extraFilters.join('&'))
          .then(response => {
            this.loading = false
            console.log(response.data)
            this.movimentacoes = response.data.result
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    windowMovimentacaoProcesso,
    abrir (id, notify) {
      viewNotify(notify)
      this.windowMovimentacaoProcesso(id)
    }
  }
}
</script>
