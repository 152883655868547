export const TYPE_LOG = 0
export const TYPE_ALERT = 1
export const TYPE_WARNING = 2
export const TYPE_NOTICE = 3
export const TYPE_INFO = 4
export const TYPE_DEBUG = 5
export const TYPE_ERROR = 98
export const TYPE_CRITICAL = 99
export const TYPE_EMERGENCY = 100

export const STATUS_ERRO = [TYPE_ERROR, TYPE_CRITICAL, TYPE_EMERGENCY]

export const Status = {
  [TYPE_LOG]: {title: 'Log', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_ALERT]: {title: 'Alerta', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_WARNING]: {title: 'Alerta Importante', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_NOTICE]: {title: 'Aviso', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_INFO]: {title: 'Info', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_DEBUG]: {title: 'Debug', class: 'log-type-' + TYPE_LOG, color: '#000000'},
  [TYPE_ERROR]: {title: 'Erro', class: 'log-type-' + TYPE_LOG, color: '#FF3E3E'},
  [TYPE_CRITICAL]: {title: 'Crítico', class: 'log-type-' + TYPE_LOG, color: '#8F0000'},
  [TYPE_EMERGENCY]: {title: 'Emergência', class: 'log-type-' + TYPE_LOG, color: '#f80000'},
}

export const StatusTitle = function (s) {
  if (typeof Status[s] === 'undefined') {
    return 'Unknow status code'
  }
  return Status[s].title
}
