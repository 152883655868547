<template>
  <card-item legend="Advogados com mais processos" icon="user-friends" icon-type="fa">
    <template v-slot:card-icon>
      <svg style="width: 24px; height: 24px" fill="currentColor" fill-rule="evenodd" height="100%" viewBox="0 0 24 24"
           width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M12 14h10v-4H12zm0 8v-4h10v4z" opacity=".8"></path>
        <path d="M2 14h10v-4H2zm7 8h3v-4H9zM2 6h7V2H2z"></path>
      </svg>
    </template>
    <div class="card-content min-pad" v-show="processos && processos.length">
      <div style="width: 100%" id="container-chart-processos-advogados-stats"></div>
    </div>
    <div class="col-grow-1 h-full flex items-center justify-center" v-if="!(processos && processos.length)">
      <no-data rotate />
    </div>
<!--    <div class="diviser"></div>
    <a class="card-btn">
      <u-icon name="arrow_forward"/>
      Acessar monitoramento
    </a>-->
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import Highcharts from 'highcharts'
import Mixin from './mixin'
import NoData from "components/dashboard/includes/NoData"

const mock = {
  'processos': []
}

export default {
  name: "ProcessosAdvogadosStats",
  mixins: [Mixin],
  components: {NoData, CardItem},
  props: ['data'],
  data() {
    return {
      processos: [],
      intervalo: []
    }
  },
  mounted() {
    setTimeout(() => {
      this.gerarGrafico()
    }, 300)
  },
  computed: {
    acessos() {
      return this.basicData?.processos?.processosPorAdvogado || mock
    }
  },
  watch: {
    acessos (value) {
      this.processos = value.map(v => +v.c)
      this.intervalo = value.map(v => v.name)
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.series[0].setData(this.processos)
          this.chart.xAxis[0].categories = this.intervalo
          this.chart.xAxis[0].setCategories(this.intervalo)
          this.chart.redraw()
        }
      })
    }
  },
  methods: {
    gerarGrafico() {
      this.$nextTick(() => {
        this.processos = this.acessos.processos.map(v => v.c)

        Highcharts.setOptions({
          lang: {
            thousandsSep: '.'
          }
        })

        this.chart = new Highcharts.chart('container-chart-processos-advogados-stats', {
          chart: {
            height: 300,
            type: 'column'
          },
          title: {
            text: null
          },

          yAxis: {
            title: {
              text: null
            },
            min: 0,
            minRange: 10
          },

          xAxis: {
            accessibility: {
              // rangeDescription: 'Range: 2010 to 2017'
            },
            categories: this.intervalo
          },


          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          },

          plotOptions: {
            line: {
              dataLabels: {
                enabled: true
              },
              enableMouseTracking: false
            }
          },

          series: [
            {
              name: 'Quantidade de processos',
              data: this.processos
            }
          ],

          responsive: {
            rules: [{
              condition: {},
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom'
                }
              }
            }]
          }

        })
      })
    }
  }
}
</script>
