import http from '../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = `/api/processos/notificacao-movimentacao?page=${page}&limit=${limit}${filtros}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const viewNotify = (id) => {
  let url = `/api/processos/notificacao-movimentacao/${id}/view`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
