<template>
  <card-item :loading="dashboard.loading" :alert-border="alertType" legend="Error Reporting" ch-legend="ERROS E ALERTAS DAS ÚLTIMAS 24H SÃO REGISTRADOS AQUI" icon="user-friends" icon-type="fa">
    <template v-slot:card-icon>
      <svg style="width: 24px; height: 24px" fill="currentColor" fill-rule="evenodd" height="100%" viewBox="0 0 24 24" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M4 6c-1.125 0-2 .88-2 2v10h2v-5h2v5h2V8c0-1.12-.875-2-2-2H4zm0 5V8h2v3l-2 2v-2zm7-5c-1.125 0-2 .88-2 2v10h2v-5h2c1.125 0 2-.88 2-2V8c0-1.12-.875-2-2-2h-2zm0 5V8h2v3l-2 2v-2zm7-3h2l-2 2v6h-2v2h6v-2h-2V8h2V6h-6v2z"></path><path d="M4 13v-2h2zm7 0v-2h2zm7-3V8h2z" opacity=".6"></path></svg>
    </template>
    <div v-if="!errors || !errors.length" class="card-content">
      Nenhum erro crítico ou alarmante detectado nas últimas 24h
    </div>
    <div style="max-height: 325px; overflow: auto" v-else>
      <card-item-link v-for="e in errors" :key="e.id" :legend="Status.StatusTitle(e.type)" :subtitle="e.message">
        <template v-slot:card-body="props">
          <div class="card-h-content">
            <div class="content1">
              <span :style="{color: Status.Status[e.type].color}">{{ Status.StatusTitle(e.type) }}</span>
              <div class="content2" style="font-size: 10px">{{ e.date.date|formatDate }}</div>
              <div class="content2">{{ e.message }}</div>
            </div>
          </div>
        </template>
      </card-item-link>
    </div>
    <template v-slot:card-footer>
      <div class="diviser"></div>
      <a class="card-btn">
        <u-icon name="arrow_forward"/>
        Ir para o painel de monitoramento da API
      </a>
    </template>
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import Mixin from './mixin'
import * as Status from '@/domain/logs/helpers/LogType'
import CardItemLink from "@/reuse/card/CardItemLink"

export default {
  name: "SoftwareLogs",
  mixins: [Mixin],
  components: {CardItemLink, CardItem},
  computed: {
    Status () {
      return Status
    },
    alertType () {
      return this.errors.length ? 'error' : null
    },
    errors() {
      if (!this.basicData || !this.basicData.apiErrors) {
        return []
      }
      return this.basicData.apiErrors
    }
  }
}
</script>
