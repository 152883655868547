<template>
  <grid-item
      :loading="dashboard.loading"
      legend="Análise de Leilões"
      icon="gavel" icon-type="fa"
      class="card-agenda-leilao"
      ch-legend="HOJE E AMANHÃ"
      :link="() => $router.push({name: 'leiloes.lista'})"
  >
    <template v-if="basicData">
      <card-item-link @click.native="$router.push({name: 'leiloes.show', params: {id: leilao.id}})"
                      legend-class="titulo-leilao"
                      v-for="leilao in leiloes" :key="leilao.id"
                      :icon="null"
                      icon-type="fa"
                      :legend="leilao.titulo.toLowerCase()"
                      :subtitle="montaDescricao(leilao)"/>
    </template>
    <card-item-link no-click
                    v-if="!basicData || !basicData.leiloes || (!basicData.leiloes.hoje.length && !basicData.leiloes.amanha.length)"
                    legend="Nenhum leilão hoje ou amanhã" icon-type="fa"/>
    <template v-slot:card-footer>
<!--      <div class="diviser m-t-xs"></div>
      <a @click="$router.push({name: 'leiloes.lista'})" class="card-btn">
        <u-icon name="arrow_forward"/>
        Acessar todos os leilões
      </a>-->
      <div class="card-footer-center">
        <a @click="$router.push({name: 'leiloes.lista'})" class="card-btn-center">
          Cadastrar Leilão
        </a>
      </div>
    </template>
  </grid-item>
</template>

<script>
import GridItem from '@/components/dashboard/includes/GridItem'
import CardItemLink from '@/reuse/card/CardItemLink'
import Mixin from './mixin'

export default {
  name: "AgendaLeiloes",
  mixins: [Mixin],
  components: {CardItemLink, GridItem},
  computed: {
    leiloes() {
      let leiloes = []
      if (this.basicData.leiloes.hoje) {
        this.basicData.leiloes.hoje.map(l => leiloes.push(l))
      }
      if (this.basicData.leiloes.amanha) {
        this.basicData.leiloes.amanha.map(l => leiloes.push(l))
      }
      return leiloes
    }
  },
  methods: {
    montaDescricao(l) {
      let dia, hora, desc
      let diaLeilao = Number(this.$options.filters.formatDate(l.dataProximoLeilao.date, 'dd'))
      let hoje = new Date()
      if (Number(hoje.getDate()) === diaLeilao) {
        dia = 'Hoje'
      } else {
        dia = 'Amanhã'
      }
      hora = this.$options.filters.formatDate(l.dataProximoLeilao.date, 'HH:mm')
      desc = `${dia}, ${hora}. ${l.totalLotes} lote${Number(l.totalLotes) > 1 ? 's' : ''}`
      return desc
    }
  }
}
</script>

<style lang="stylus">
.card-agenda-leilao {
  .titulo-leilao {
    text-transform capitalize !important
  }
}
</style>
