<script>
/* eslint-disable */
import {UInput, USelect} from 'uloc-vue'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import CardWidget from "@/reuse/card/Widget"
import CardColumn from "@/reuse/card/Column"
import CardItem from "@/reuse/card/Card"
import CardItemLink from "@/reuse/card/CardItemLink"
import SoftwareStatus from '@/components/dashboard/components/DashWidgets/SoftwareStatus'
import AcessoStats from "@/components/dashboard/components/DashWidgets/AcessoStats"
import AgendaLeiloes from "@/components/dashboard/components/DashWidgets/AgendaLeiloes"
import CadastroStats from "@/components/dashboard/components/DashWidgets/CadastroStats"
import FinanceiroStats from "@/components/dashboard/components/DashWidgets/Faturamento"
import TarefasStats from "@/components/dashboard/components/DashWidgets/Tarefas"
import {getDashboardData} from "@/domain/estatisticas/services"
import SoftwareLogs from "@/components/dashboard/components/DashWidgets/SoftwareLogs"
import SoftwareAvisos from "@/components/dashboard/components/DashWidgets/SoftwareAvisos"
import Processos from "@/components/dashboard/components/DashWidgets/Processos"
import TasksUsuarioMesAtualStats from "@/components/dashboard/components/DashWidgets/TasksUsuarioMesAtualStats"
import TasksUsuarioStats from "@/components/dashboard/components/DashWidgets/TasksUsuarioStats"
import ProcessosClientesStats from "@/components/dashboard/components/DashWidgets/ProcessosClientesStats"
import ProcessosAdvogadosStats from "@/components/dashboard/components/DashWidgets/ProcessosAdvogadosStats"
import VueGridLayout from 'vue-grid-layout'
import Vue from 'vue'

Vue.component('AgendaLeiloes', AgendaLeiloes)
Vue.component('AcessoStats', AcessoStats)
Vue.component('SoftwareStatus', SoftwareStatus)
Vue.component('ProcessosAdvogadosStats', ProcessosAdvogadosStats)
Vue.component('ProcessosClientesStats', ProcessosClientesStats)
Vue.component('TasksUsuarioMesAtualStats', TasksUsuarioMesAtualStats)
Vue.component('TasksUsuarioStats', TasksUsuarioStats)
Vue.component('SoftwareAvisos', SoftwareAvisos)
Vue.component('SoftwareLogs', SoftwareLogs)
Vue.component('TarefasStats', TarefasStats)
Vue.component('FinanceiroStats', FinanceiroStats)
Vue.component('CadastroStats', CadastroStats)
Vue.component('Processos', Processos)

export default {
  name: 'Dashboard1',
  provide: function () {
    return {
      dashboard: this
    }
  },
  mixins: [],
  components: {
    ProcessosAdvogadosStats,
    ProcessosClientesStats,
    TasksUsuarioMesAtualStats,
    TasksUsuarioStats,
    SoftwareAvisos,
    SoftwareLogs,
    TarefasStats,
    FinanceiroStats,
    CadastroStats,
    AgendaLeiloes,
    AcessoStats,
    SoftwareStatus,
    Processos,
    CardItemLink,
    CardItem,
    CardColumn,
    CardWidget,
    ErpInput,
    ErpSField,
    UInput,
    USelect,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  data() {
    return {
      loading: false,
      basicData: null,
      layout: [
        {"x": 0, "y": 0, "w": 4, "h": 6, "i": "0", minW: 4, minH: 4, component: 'AgendaLeiloes'},
        {"x": 4, "y": 0, "w": 8, "h": 10, "i": "1", minW: 4, minH: 4, component: 'AcessoStats'},
        {"x": 8, "y": 0, "w": 4, "h": 5, "i": "2", minW: 4, minH: 5, component: 'SoftwareStatus'},
        {"x": 0, "y": 5, "w": 4, "h": 7, "i": "3", minW: 4, minH: 4, component: 'TarefasStats'},
        {"x": 4, "y": 5, "w": 4, "h": 12, "i": "4", minW: 4, minH: 4, component: 'CadastroStats', props: {enableFilters: false}},
        {"x": 8, "y": 5, "w": 4, "h": 7, "i": "5", minW: 4, minH: 4, component: 'FinanceiroStats'},
        {"x": 0, "y": 10, "w": 4, "h": 6, "i": "6", minW: 4, minH: 4, component: 'Processos'},
        {"x": 4, "y": 10, "w": 4, "h": 10, "i": "7", minW: 4, minH: 4, component: 'TasksUsuarioMesAtualStats'},
        {"x": 8, "y": 10, "w": 4, "h": 12, "i": "8", minW: 4, minH: 4, component: 'SoftwareLogs'},
        {"x": 0, "y": 15, "w": 4, "h": 4, "i": "9", minW: 4, minH: 4, component: 'SoftwareAvisos'},

        {"x": 0, "y": 20, "w": 4, "h": 10, "i": "11", minW: 4, minH: 6, component: 'TasksUsuarioStats'},

        {"x": 0, "y": 25, "w": 4, "h": 7, "i": "12", minW: 4, minH: 4, component: 'ProcessosAdvogadosStats'},
        {"x": 0, "y": 30, "w": 4, "h": 6, "i": "13", minW: 4, minH: 4, component: 'ProcessosClientesStats'},
        /*{"x":0,"y":0,"w":1,"h":2,"i":"0"},
        {"x":2,"y":0,"w":2,"h":4,"i":"1"},
        {"x":4,"y":0,"w":2,"h":5,"i":"2"},
        {"x":6,"y":0,"w":2,"h":3,"i":"3"},
        {"x":8,"y":0,"w":2,"h":3,"i":"4"},
        {"x":10,"y":0,"w":2,"h":3,"i":"5"},
        {"x":0,"y":5,"w":2,"h":5,"i":"6"},
        {"x":2,"y":5,"w":2,"h":5,"i":"7"},
        {"x":4,"y":5,"w":2,"h":5,"i":"8"},
        {"x":6,"y":3,"w":2,"h":4,"i":"9"},
        {"x":8,"y":4,"w":2,"h":4,"i":"10"},
        {"x":10,"y":4,"w":2,"h":4,"i":"11"},
        {"x":0,"y":10,"w":2,"h":5,"i":"12"},
        {"x":2,"y":10,"w":2,"h":5,"i":"13"},
        {"x":4,"y":8,"w":2,"h":4,"i":"14"},
        {"x":6,"y":8,"w":2,"h":4,"i":"15"},
        {"x":8,"y":10,"w":2,"h":5,"i":"16"},
        {"x":10,"y":4,"w":2,"h":2,"i":"17"},
        {"x":0,"y":9,"w":2,"h":3,"i":"18"},
        {"x":2,"y":6,"w":2,"h":2,"i":"19"}*/
      ],
    }
  },
  computed: {},
  mounted() {
    this.loadBasicData()
    this.$interval && clearInterval(this.$interval)
    this.$interval = setInterval(() => {
      this.loadBasicData(false)
    }, 60000) // @TODO: Mudar para 60s no mínimo. 5s só para testes
  },
  beforeDestroy() {
    this.$interval && clearInterval(this.$interval)
  },
  methods: {
    loadBasicData(enableLoading = true) {
      if (enableLoading) this.loading = true
      getDashboardData()
          .then(response => {
            if (enableLoading) this.loading = false
            this.basicData = response.data
          })
          .catch(error => {
            if (error.data.status === 401) {
              setTimeout(() => this.$router.push('/logout'), 500) // TMP
              return
            }
            this.alertApiError(error)
            this.$interval && clearInterval(this.$interval)
            if (enableLoading) this.loading = false
          })
    }
  },
  meta: {
    title: 'Dashboard',
    name: 'Dashboard'
  }
}
</script>

<template>

        <div class="dash-content">
          <grid-layout
              :layout.sync="layout"
              :col-num="12"
              :row-height="30"
              :is-draggable="true"
              :is-resizable="true"
              :is-mirrored="false"
              :vertical-compact="true"
              :margin="[10, 10]"
              :use-css-transforms="true"
              class="grid-widget"
          >

            <grid-item v-for="item in layout"
                       :x="item.x"
                       :y="item.y"
                       :w="item.w"
                       :minW="item.minW"
                       :h="item.h"
                       :minH="item.minH"
                       :i="item.i"
                       :key="item.i"
                       drag-allow-from=".vue-draggable-handle"
                       drag-ignore-from=".no-drag"
            >
              <component v-bind:is="item.component" v-bind="item.props"/>
            </grid-item>
          </grid-layout>
          <!--        <card-widget>
                    <card-column>
                      <agenda-leiloes/>
                      <tarefas-stats/>
                      <processos />
                      <software-avisos/>
                      <tasks-usuario-stats/>
                    </card-column>
                    <card-column>
                      <acesso-stats/>
                      <cadastro-stats/>
                      <tasks-usuario-mes-atual-stats/>
                    </card-column>
                    <card-column>
                      <software-status/>
                      <financeiro-stats/>
                      <software-logs />
                      <processos-clientes-stats/>
                      <processos-advogados-stats/>
                    </card-column>
                  </card-widget>-->
        </div>
</template>
