<template>
<div class="flex flex-center items-center column">
  <svg :style="rotate ? 'transform: rotate(-90deg)' : ''" width="80" height="80" viewBox="0 0 80 80"><g fill="none" fill-rule="evenodd"><path d="M0 0h80v80H0z"></path><g stroke="#CBCCCD" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M10.5 34.5h7M22.442 34.5H52.5A1.5 1.5 0 0 1 54 36v9.003a1.5 1.5 0 0 1-1.5 1.5H7M7 54.503h33.563a1.5 1.5 0 0 1 1.5 1.5V65a1.5 1.5 0 0 1-1.5 1.5L7 66.503"></path><path fill="#CBCCCD" d="M73 16.003v9a1.5 1.5 0 0 1-1.5 1.5H7v-12h64.5a1.5 1.5 0 0 1 1.5 1.5z"></path><path d="M7 60.503V8.513M7 72.5v-5.997"></path></g></g></svg>
  <div class="m-t">
    Não há dados para exibição do relatório.
  </div>
</div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    rotate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
