<template>
  <card-item legend="Quantidade de tarefas por usuário" icon="user-friends" icon-type="fa">
    <template v-slot:card-icon>
      <svg style="width: 24px; height: 24px" fill="currentColor" fill-rule="evenodd" height="100%" viewBox="0 0 24 24"
           width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M12 14h10v-4H12zm0 8v-4h10v4z" opacity=".8"></path>
        <path d="M2 14h10v-4H2zm7 8h3v-4H9zM2 6h7V2H2z"></path>
      </svg>
    </template>
    <div class="card-content min-pad" v-show="tarefas && tarefas.length">
      <div style="width: 100%" id="container-chart-task-user-stats"></div>
    </div>
    <div class="col-grow-1 h-full flex items-center justify-center" v-if="!(tarefas && tarefas.length)">
      <no-data />
    </div>
<!--    <div class="diviser"></div>
    <a class="card-btn">
      <u-icon name="arrow_forward"/>
      Acessar monitoramento
    </a>-->
  </card-item>
</template>

<script>
import CardItem from '@/components/dashboard/includes/GridItem'
import Highcharts from 'highcharts'
import Mixin from './mixin'
import NoData from "components/dashboard/includes/NoData"

const mock = {
  'tarefas': []
}

export default {
  name: "TasksUsuarioStats",
  mixins: [Mixin],
  components: {NoData, CardItem},
  props: ['data'],
  data() {
    return {
      tarefas: [],
    }
  },
  mounted() {
    this.gerarGrafico()
  },
  computed: {
    acessos() {
      return this.basicData?.tasks?.quantidadeTarefasPorUsuario || mock
    }
  },
  watch: {
    acessos (value) {
      this.tarefas = value.map(v => ({y: +v.c, name: v.name}))
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.series[0].setData(this.tarefas)
          this.chart.redraw()
        }
      })
    }
  },
  methods: {
    gerarGrafico() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.tarefas = this.acessos.tarefas.map(v => v.c)
          Highcharts.setOptions({
            lang: {
              thousandsSep: '.'
            }
          })

          this.chart = new Highcharts.chart('container-chart-task-user-stats', {
            chart: {
              height: 300,
              type: 'pie'
            },
            title: {
              text: null
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage:.2f}%)'
            },

            yAxis: {
              title: {
                text: null
              },
              min: 0,
              minRange: 10
            },

            xAxis: {
              accessibility: {
                // rangeDescription: 'Range: 2010 to 2017'
              },
            },


            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'middle'
            },

            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true
                },
                enableMouseTracking: false
              }
            },

            series: [
              {
                name: 'Quantidade de tarefas',
                data: this.tarefas
              }
            ],

            responsive: {
              rules: [{
                condition: {},
                chartOptions: {
                  legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                  }
                }
              }]
            }

          })
        })
      }, 300)
    }
  }
}
</script>
